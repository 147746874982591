import {
  Box,
  ButtonBase,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const categories = [
  {
    id: "public-websites",
    title: "Public Websites",
    cols: 6,
    list: [
      {
        title: "Cosmily",
        url: "https://cosmily.com/",
        technologies: ["Next.js", "Api", "Strapi", "Material UI"],
        cover: "thumb-cosmily",
      },

      {
        title: "RestartiX",
        url: "https://restartix.ro/",
        technologies: ["Design", "WordPress", "ES6"],
        cover: "thumb-restartix",
      },
      {
        title: "SOSOfresh",
        url: "https://sosofresh.ro/",
        technologies: ["WordPress"],
        cover: "thumb-sosofresh",
      },
      {
        title: "Cosmin Pleșa",
        url: "https://plesacosmin.ro/",
        technologies: ["Design", "WordPress", "ES5"],
        cover: "thumb-plesacosmin",
      },
    ],
  },
  {
    id: "apps",
    title: "Apps",
    list: [
      {
        title: "Varrial",
        technologies: ["Laravel", "MySQL", "Vue.js"],
        cover: "thumb-varrial",
      },
      {
        title: "Tab Manager",
        technologies: ["React", "Chrome SDK", "Material UI"],
        cover: "thumb-tabmanager",
      },
      {
        title: "RestartiX Stats",
        technologies: ["Next.js", "Api", "Strapi"],
        cover: "thumb-restartixadmin",
      },
    ],
  },
  {
    id: "games",
    title: "Games",
    cols: 6,
    list: [
      {
        title: "Memory Game",
        url: "https://seriously-smoked-memory-game.firebaseapp.com/",
        technologies: ["Design", "React", "Firebase", "Animations"],
        cover: "thumb-memory",
      },
      {
        title: "Dungeon Crawler",
        url: "http://dungeon.ntropy.space/",
        technologies: ["Design", "React", "CSS Animations"],
        cover: "thumb-dungeon",
      },
    ],
  },
  {
    id: "experiments",
    title: "Experiments",
    list: [
      {
        title: "Spinner",
        url: "http://spinner.ntropy.space/",
        technologies: ["ES6", "requestAnimationFrame"],
        cover: "thumb-spinner",
      },
      {
        title: "Crafting",
        url: "http://crafting.ntropy.space/",
        technologies: ["React", "CSS Animations"],
        cover: "thumb-crafting",
      },
      {
        title: "Hero Customizer",
        url: "http://lab.ntropy.space/games/mechanics/customizer/",
        technologies: ["ES6", "CSS"],
        cover: "thumb-customizer",
      },
      {
        title: "Soundwaves",
        url: "http://lab.ntropy.space/experiments/soundwaves/",
        technologies: ["ES6", "Three.js"],
        cover: "thumb-soundwaves",
      },
      {
        title: "L-system Fractals",
        url: "https://ntropy.space/fr.html",
        technologies: ["ES6", "Canvas"],
        cover: "thumb-fractals",
      },
      {
        title: "Game of Life",
        url: "https://ntropy.space/gameoflife.html",
        technologies: ["ES6", "Canvas"],
        cover: "thumb-gameoflife",
      },
      {
        title: "Chaos Game",
        url: "https://ntropy.space/chaosgame.html",
        technologies: ["ES6", "Canvas"],
        cover: "thumb-chaosgame",
      },
      {
        title: "Nodes",
        url: "http://lab.ntropy.space/apps/nodes/",
        technologies: ["ES6", "SVG", "D3.js"],
        cover: "thumb-nodes",
      },
      {
        title: "Scheduler",
        url: "http://lab.ntropy.space/apps/schedulr/",
        technologies: ["jQuery"],
        cover: "thumb-scheduler",
      },
      {
        title: "Tree DnD",
        url: "http://lab.ntropy.space/apps/tree/",
        technologies: ["React"],
        cover: "thumb-treednd",
      },
    ],
  },
];

export default function Home() {
  return (
    <Container>
      <Box>
        <Box sx={{ my: 4 }}>
          <Stack direction={["column", "row"]} justifyContent="space-between">
            <Box>
              <Typography component="h1" variant="h3">
                Ovidiu Alexandrescu
              </Typography>
              <Typography component="h2" variant="h4" color="primary">
                Frontend Developer
              </Typography>
            </Box>

            <Box>
              <IconButton
                href="https://github.com/owystyle"
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
              >
                <GitHubIcon />
              </IconButton>
              <IconButton
                href="https://www.linkedin.com/in/ovidiualexandrescu/"
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
              >
                <LinkedInIcon />
              </IconButton>
            </Box>
          </Stack>
        </Box>

        <Box sx={{ p: 2, border: "1px dashed rgba(0,0,0,.2)" }}>
          <Typography variant="p">
            "I am a self-taught and dedicated creator of web-based user
            interfaces. My expertise lies in designing and developing custom
            applications and websites, starting from the ground up, to meet the
            unique needs of my clients. Growing up alongside the internet and
            technology has fueled my passion for expanding my skills and
            knowledge in this ever-evolving field. I thrive on staying
            up-to-date with the latest technologies and incorporating them into
            my work, while also infusing it with my own personal style. I am
            driven by a strong desire to deliver exceptional user experiences
            and constantly push the boundaries of what I can achieve."
          </Typography>
        </Box>
      </Box>

      {categories.map(({ title, list, id, cols }) => (
        <Box key={id} sx={{ my: 8 }}>
          <Box sx={{ mb: 2 }}>
            <Typography component="h2" variant="h4">
              {title}
            </Typography>
          </Box>

          <Grid container spacing={4}>
            {list.map((item) => (
              <Grid key={item.id} item xs={12} md={cols || 4}>
                <ButtonBase
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Paper square>
                    <Box>
                      <img src={`/assets/${item.cover}.jpg`} alt={item.title} />
                    </Box>
                    <Box sx={{ py: 2, px: 3, textAlign: "left" }}>
                      <Typography component="h3" variant="h6">
                        {item.title}
                      </Typography>
                      <Typography>{item.technologies.join(", ")}</Typography>
                    </Box>
                  </Paper>
                </ButtonBase>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Container>
  );
}
