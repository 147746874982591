import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import "@fontsource-variable/inter";
import "@fontsource-variable/inter-tight";

// A custom theme for this app
const theme = createTheme({
  palette: {
    text: {
      primary: "#1a202c",
    },
    primary: {
      main: "#4c51bf",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    fontFamily: "Inter Tight Variable",
    fontSize: 16,

    h1: {
      fontFamily: "Inter Tight Variable",
      fontWeight: 600,
    },
    h2: {
      fontFamily: "Inter Tight Variable",
      fontWeight: 600,
    },
    h3: {
      fontFamily: "Inter Tight Variable",
      fontWeight: 600,
    },
    h4: {
      fontFamily: "Inter Tight Variable",
      fontWeight: 600,
    },
    h5: {
      fontFamily: "Inter Tight Variable",
      fontWeight: 600,
    },
    h6: {
      fontFamily: "Inter Tight Variable",
      fontWeight: 600,
    },
  },
});

export default theme;
